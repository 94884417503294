/* You can add global styles to this file, and also import other style files */
@import "assets/css/main.min.css";
@import "assets/css/font-snippet.css";
@import "assets/css/critical.min.css";

.mod_topnav li {
  cursor: pointer;
}

a.mod_button {
  cursor: pointer;
}.mod_button.var_secondary:disabled,.mod_button:disabled{
   background-color: #dedede;
   color: #666666;
 }

.ng-bar-placeholder {
  height: 5px !important;
}

.swal2-modal {

}

.swal2-popup {
  border-radius: unset !important;
  font-size: 1rem !important;
  width: 34em !important;
  padding: 1.25rem !important;
}

.swal2-title {
  font-size: 1.2rem !important;
  color: #0F05A0 !important;
}

.swal2-styled.swal2-confirm {
  background-color: #0F05A0 !important;
  border-radius: unset !important;
}

.swal2-styled.swal2-cancel {
  border-radius: unset !important;
}

.swal2-close {
  color: #0F05A0 !important;
  font-size: 1.5em !important;
  margin-top: -0.5rem !important;
  margin-right: -0.5rem !important;
  margin-bottom: -1.2em !important;
}

.flex-center {
  justify-content: center !important;
  align-items: center !important;
}

.button-grey {
  background: #6e7881;
  border-color: #6e7881;
  cursor: unset;
}

.icon-grey {
  color: #6e7881 !important;
}

.mod_formtextinput input[disabled] {
  opacity: .5 !important;
}

#bestaetigungsButton {
  border: 1px solid #0F05A0;
  background: none;
  color: #0F05A0;
  font-weight: normal;
  border-radius: 5px;
  padding: 0 10px;
}
