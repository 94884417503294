@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=fef75070-c6b6-491f-a898-01b46ea3a949");

@font-face {
  font-family: "HelveticaNeueLTW01_41488878";
  src: url("f9c5199e-a996-4c08-9042-1eb845bb7495.woff2") format("woff2"), url("2a34f1f8-d701-4949-b12d-133c1c2636eb.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeueLTW01_55Roman";
  src: url("08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"), url("08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeueLTW05_65Medium";
  src: url("240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"), url("7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeueLTW01_56It";
  src: url("de68be2a-5d0e-4b8d-b3eb-940f75503e2a.woff2") format("woff2"), url("31029e78-79a0-4940-b82d-2e3c238e1355.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeueLTW01_71488914";
  src: url("800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"), url("7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeueLTW05_85Heavy";
  src: url("7e42a406-9133-48c0-a705-4264ac520b43.woff2") format("woff2"), url("837750e9-3227-455d-a04e-dc76764aefcf.woff") format("woff");
}
